import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import MarkdownPage from "../pages/MarkdownPage";
import { Styles } from "../styles/styles";

import AboutMarkdown from '../content/es/about.md';
import ActivitiesMarkdown from '../content/es/activities.md';


const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        <Route
          key="Home"
          path={["/"]}
          exact={true}
          sensitive={false}
          render={() => <Home />}
        />
        <Route
          key="About"
          path={["/quienes-somos"]}
          exact={true}
          sensitive={false}
          render={() => <MarkdownPage module={AboutMarkdown} />}
        />
        <Route
          key="Activities"
          path={["/actividades"]}
          exact={true}
          sensitive={false}
          render={() => <MarkdownPage module={ActivitiesMarkdown} />}
        />
        <Route
          key="Contact"
          path={["/contacto"]}
          exact={true}
          sensitive={false}
          render={() => <Contact />}
        />
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;

import { lazy } from "react";

import AboutContent from "../../content/AboutContent.json";
import IntroContent from "../../content/IntroContent.json";
import MissionContent from "../../content/MissionContent.json";
import ParticipateContent from "../../content/ParticipateContent.json";

const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));

const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        direction="right"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        src="img/cooking.jpg"
        id="intro"
      />

      <ContentBlock
        direction="left"
        title={MissionContent.title}
        content={MissionContent.text}
        src="img/lions.jpg"
        id="mission"
      />

      <ContentBlock
        direction="right"
        title={AboutContent.title}
        content={AboutContent.text}
        // section={AboutContent.section}
        src="img/wok.jpg"
        id="about"
      />

      <MiddleBlock
        title={ParticipateContent.title}
        content={ParticipateContent.text}
        button={ParticipateContent.button}
      />
    </Container>
  );
};

export default Home;

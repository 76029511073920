import { Col, Drawer, Row } from "antd";
import { Image } from 'primereact/image';
import { useState } from "react";
import { TFunction, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "../../common/Container";

import {
  Burger,
  CustomNavLinkSmall,
  HeaderSection,
  Label,
  LogoContainer,
  Menu,
  NotHidden,
  Outline
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = ({ onClick = () => { } }) => {
    return (
      <>
        <CustomNavLinkSmall>
          <Link to="/" onClick={onClick}>Inicio</Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/quienes-somos" onClick={onClick}>Quienes somos</Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/actividades" onClick={onClick}>Actividades</Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/contacto" onClick={onClick}>Contacto</Link>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <Image src="/img/logo.png" alt="Image" height="150" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem onClick={toggleButton} />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);

import { lazy } from "react";

import IntroContent from "../../content/IntroContent.json";
import { APIProvider, Map, MapCameraChangedEvent, Pin, AdvancedMarker } from '@vis.gl/react-google-maps';



const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));

type Poi = { key: string, location: google.maps.LatLngLiteral }
const locations: Poi[] = [
  { key: 'Asociación Cultural Puente a China', location: { lat: 40.402172182423634, lng: -3.718314698564654 } },
];

const PoiMarkers = (props: { pois: Poi[] }) => {
  return (
    <>
      {props.pois.map((poi: Poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}>
          <Pin background={'#AA221B'} glyphColor={'#F7CD67'} borderColor={'#A13E40'} />
        </AdvancedMarker>
      ))}
    </>
  );
}


const Contact = () => {
  return (
    <Container>
      <ScrollToTop />
      <h1>Contacto</h1>
      <p>¿Tienes alguna pregunta o te gustaría saber más sobre nuestras actividades y eventos? Estamos aquí para ayudarte. No dudes en ponerte en contacto con nosotros a través de nuestro correo electrónico, teléfono o visitándonos en nuestra sede en Madrid. Nuestro equipo estará encantado de asistirte y brindarte toda la información que necesites para que puedas disfrutar al máximo de todo lo que la Asociación Cultural China de Madrid tiene para ofrecer.</p>

      <p>Puedes llamarnos o mandarnos un whatsapp al <strong><a href="tel:648772030">648772030</a></strong>, o si te viene mejor mándanos un correo a <strong><a href="mailto:info@puenteachina.es">info@puenteachina.es</a></strong>.</p>

      <APIProvider apiKey={'AIzaSyBsiLPHtFex8tMTQ7TJJtRpdHsvsH_H_wc'} onLoad={() => console.log('Maps API has loaded.')}>
        <h2>¡Pásate a saludar!</h2>
        <p>También puedes encontrarnos fácilmente utilizando el widget de Google Maps a continuación, que te mostrará la ubicación exacta de nuestra sede. Ven a conocernos, ¡siempre estamos felices de recibir nuevas caras y de compartir juntos la fascinante cultura china!</p>
        <Map
          style={{ width: '100%', height: '400px' }}
          defaultZoom={14}
          defaultCenter={locations[0].location}
          mapId={'ea0276a8e6824d8f'}
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
          }>
          <PoiMarkers pois={locations} />

        </Map>
      </APIProvider>




    </Container>

  );
};

export default Contact;

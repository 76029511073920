import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import Markdown from 'react-markdown';


import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";

interface MarkdownPageProps {
    module: string;
}

export default function MarkdownPage({ module }: MarkdownPageProps) {
    const [content, setContent] = useState('');
    fetch(module).then((response) => response.text()).then((text) => setContent(text)).catch((error) => setContent(error));

    return (
        <Container>
            <ScrollToTop />
            <Fade cascade triggerOnce>
                {
                    content.split(/^(?=#+ )/m).filter(Boolean).map((section, index) => {
                        return(<Markdown>{section}</Markdown>);
                    })
                }
            </Fade>
        </Container>
    );
}

import { Col, Row } from "antd";
import { TFunction, withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import i18n from "i18next";
import {
  FooterSection,
  Language,
  Para
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para><a href="tel:648772030">Tel: 648772030</a></Para>
              <Para><a href="mailto:info@puenteachina.es">info@puenteachina.es</a></Para>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>Asociación Cultural Puente a China</Para>
              <Para>Calle San Epifanio 3</Para>
              <Para>28005 Madrid</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
